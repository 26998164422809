import Router from "./router/Router";
import './assets/css/style.css';
// import './assets/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
       <ToastContainer />
      <Router />
    </div>
  );
}

export default App;
