import React from 'react'

export default function Loader() {
    return (
        <div className='loader_section'>
            <div className='loding_icon'>
                <img src={require('../assets/img/sugar.png')} />
            </div>
        </div>
    )
}
